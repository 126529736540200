import React, { useState, useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateAchBankAccount } from '@src/hooks/queries/vendor_ach_bank_account';
import { IBusinessVendor } from '@src/types/business_vendors';
import { camelizeKeys } from '@src/utils/transform_keys';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';
import MutationStatus from '@src/components/utils/mutation_status';

import AddAchBankForm from '../form/ach_form_account';
import { IBusinessAchAccountInput } from '../form/schema';

import styles from './styles.module.scss';

interface IAchActionProps extends IUseModalProps {
  formId: string,
  vendor: IBusinessVendor;
  businessId: number;
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>;
}

const BankAccountModal = ({
  formId,
  vendor,
  businessId,
  isOpen,
  onCancel,
  onDone,
  setVendorData,
}: IAchActionProps) => {
  const addAchBankAccount = useCreateAchBankAccount();
  const [isFormValid, setIsFormValid] = useState(false);

  const { mutate: addAccount } = addAchBankAccount;

  const handleSubmit = useCallback(
    (data: IBusinessAchAccountInput) => {
      addAccount(
        {
          ...data,
          businessId,
          vendorId: vendor.id,
        },
        {
          onSuccess: (response) => {
            const vendorData = (response as unknown as { vendor: IBusinessVendor }).vendor;
            setVendorData(camelizeKeys(vendorData) as IBusinessVendor);
            onDone();
          },
        },
      );
    },
    [addAccount, vendor.id, businessId, setVendorData, onDone],
  );

  return (
    <>
      <MutationStatus mutation={ addAchBankAccount } successMessage="Add Ach Bank Account successfully." />
      <Modal
        className={ styles['modal-ach-account'] }
        show={ isOpen }
        title={ (
          <p className={ styles['vendor-profile-modal-title'] }>
            Add Bank Account Info
          </p>
        ) }
      >
        <Modal.Body>
          <AddAchBankForm
            formId={ formId }
            setIsFormValid={ setIsFormValid }
            onSubmit={ handleSubmit }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            disabled={ !isFormValid }
            form={ formId }
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useBankAccountModal = makeUseModal(BankAccountModal);

export {
  useBankAccountModal,
  BankAccountModal as default,
};
