import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useGetLimitedBusinesses } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import Form from '@src/components/ui/form';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import { businessesValidation, IBusinessesInput } from './schema';
import SelectAllBusinessCheckbox from './select_all_business_checkbox';
import SelectBusinessCheckbox from './select_business_checkbox';

import styles from './styles.module.scss';

interface IEditLinkedBusinessesFormProps {
  formId?: string,
  businessId?: TID,
  linkedBusinessIds: TID[],
  onSubmit: SubmitHandler<IBusinessesInput>,
  setIsUnlinkedBusinesses: (isValid: boolean) => void;
}

const EditLinkedBusinessesForm = ({
  formId,
  businessId,
  linkedBusinessIds,
  onSubmit,
  setIsUnlinkedBusinesses,
}: IEditLinkedBusinessesFormProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [isAnyUnlinkedChecked, setIsAnyUnlinkedChecked] = useState<boolean>(false);

  const query = useGetLimitedBusinesses({
    forClient:                   true,
    includeAccessibleBusinesses: true,
    search:                      searchValue,
    filter:                      {
      role:           'All',
      forServiceRole: {
        serviceType: 'VendorService',
        serviceRole: 'ADMIN',
      },
    },
  });

  const businesses = useMemo(() => {
    if (!query.data?.collection) return [];

    return query.data?.collection
      .filter((business) => business.id.toString() !== businessId?.toString());
  }, [query.data?.collection, businessId]);

  const linkedBusinesses = useMemo(() => {
    return businesses.filter((business) => linkedBusinessIds.includes(business.id));
  }, [businesses, linkedBusinessIds]);

  const unLinkedBusinesses = useMemo(() => {
    return businesses.filter((business) => !linkedBusinessIds.includes(business.id));
  }, [businesses, linkedBusinessIds]);

  const allBusinesses = useMemo(() => {
    const resultBusinesses: IBusiness[] = [];
    linkedBusinesses.forEach((business) => {
      resultBusinesses.push(business);
    });
    unLinkedBusinesses.forEach((business) => {
      resultBusinesses.push(business);
    });

    return resultBusinesses;
  }, [linkedBusinesses, unLinkedBusinesses]);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm<IBusinessesInput>({
    defaultValues: {
      businesses,
    },
    resolver: yupResolver(businessesValidation),
  });

  const businessesControl = useController({ control, name: 'businesses' });

  const handleSubmitError = useCallback((err) => {
    if (!err.businesses) return;

    toastr.error(
      err.businesses.message,
      'Something wrong',
    );
  }, []);

  useEffect(() => {
    setValue('businesses', linkedBusinesses);
  }, [setValue, linkedBusinesses]);

  useEffect(() => {
    setIsUnlinkedBusinesses(isAnyUnlinkedChecked);
  }, [isAnyUnlinkedChecked, setIsUnlinkedBusinesses]);

  useEffect(() => {
    const anyChecked = unLinkedBusinesses.some((business) => watch('businesses').some((selectedBusiness) => selectedBusiness.id === business.id));
    setIsAnyUnlinkedChecked(anyChecked);
  }, [searchValue, unLinkedBusinesses, watch]);

  const isLinkedBusinesses = (id: TID) => {
    return linkedBusinessIds.includes(id);
  };

  const handleUnlinkedBusinessCheckedChange = (isChecked: boolean) => {
    if (isChecked) {
      setIsAnyUnlinkedChecked(true);
    } else {
      const anyChecked = unLinkedBusinesses.some((business) => watch('businesses').some((selectedBusiness) => selectedBusiness.id === business.id));
      setIsAnyUnlinkedChecked(anyChecked);
    }
  };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit, handleSubmitError) }>
      <div className={ styles['edit-linked-businesses-container'] }>
        <div className={ styles['search-outer'] }>
          <SearchInput
            placeholder="Search business"
            value={ searchValue }
            onChange={ setSearchValue }
          />
        </div>
        <div
          className={ classNames(styles.business, 'bottom-divider m-t-12 m-b-8') }
          role="button"
          tabIndex={ 0 }
        >
          <SelectAllBusinessCheckbox
            businesses={ allBusinesses }
            defaultBusinesses={ linkedBusinesses }
            field={ businessesControl.field }
            onCheckedChange={ handleUnlinkedBusinessCheckedChange }
          />
          <span className={ styles.count }>{`${watch('businesses').length} selected`}</span>
        </div>
        <div className={ styles.businesses }>
          {
          allBusinesses.map((business, index) => (
            <div
              key={ business.id }
              className={
                classNames(
                  styles.business,
                  { disabled: isLinkedBusinesses(business.id) },
                )
              }
              role="button"
              tabIndex={ index }
            >
              <SelectBusinessCheckbox
                business={ business }
                disabled={ isLinkedBusinesses(business.id) }
                field={ businessesControl.field }
                onCheckedChange={ handleUnlinkedBusinessCheckedChange }
              />
            </div>
          ))
        }
        </div>
      </div>
    </Form>
  );
};

export {
  IEditLinkedBusinessesFormProps,
  EditLinkedBusinessesForm as default,
};
