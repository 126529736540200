import React, { useCallback } from 'react';

import { useSetIntegrationPaused } from '@src/hooks/queries/integrations/integrations';
import { IIntegration } from '@src/types/integrations/integrations';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { PauseCircleIcon, PlayCircleIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface ToggleIntegrationPauseActionProps {
  integration: IIntegration,
}

const ToggleIntegrationPauseAction = ({
  integration,
}: ToggleIntegrationPauseActionProps) => {
  const pause = useSetIntegrationPaused();
  const { mutate } = pause;

  const handleSetPaused = useCallback(() => {
    const params = { integrationId: integration.id, isPaused: integration.isEnabled };
    mutate(params);
  }, [integration.id, integration.isEnabled, mutate]);

  return (
    <>
      <MutationStatus
        mutation={ pause }
        successMessage={ integration.isEnabled ? 'Integration has been activated' : 'Integration has been paused' }
      />
      <ActionsDropdown.Action
        icon={
          integration.isEnabled ? (
            <PauseCircleIcon fontSize={ 18 } />
          ) : (
            <PlayCircleIcon fontSize={ 18 } />
          )
        }
        title={ integration.isEnabled ? 'Pause' : 'Activate' }
        onClick={ handleSetPaused }
      />
    </>
  );
};

export default React.memo(ToggleIntegrationPauseAction);
