import { useMutation } from 'react-query';

import { TDate, TID, TUUID } from '@src/types/common';
import {
  IActivateRevenueSystemDepositTrackingData,
  ICreateRevenueSystemData, IRevenueDeposit,
  IRevenueSystem,
} from '@src/types/revenue_systems';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, authorizedApiRequest } from './helpers';

interface ICreateRevenueSystemParams {
  revenueServiceId: TID,
  data: ICreateRevenueSystemData,
}

interface IGetRevenueSystemsParams {
  revenueServiceId: TID
}

interface IGetRevenueSystemsResponse {
  collection: IRevenueSystem[]
}

interface ICreateRevenueSystemResponse {
  revenueSystem: IRevenueSystem,
}

const createRevenueSystem = (
  params: ICreateRevenueSystemParams,
): Promise<ICreateRevenueSystemResponse> => {
  return apiPost(
    `/api/v1/revenue_services/${params.revenueServiceId}/revenue_systems`,
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as ICreateRevenueSystemResponse;
  });
};

const getRevenueSystems = (
  params: IGetRevenueSystemsParams,
): Promise<IGetRevenueSystemsResponse> => {
  return apiGet(
    `/api/v1/revenue_services/${params.revenueServiceId}/revenue_systems`,
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.revenueSystems as IRevenueSystem[],
    };
  });
};

interface IActivateRevenueSystemDepositTrackingParams {
  revenueServiceId: TID,
  revenueSystemId: TUUID,
  data: IActivateRevenueSystemDepositTrackingData,
}

interface IActivateRevenueSystemDepositTrackingResponse {
}

const activateRevenueSystemDepositTracking = (
  params: IActivateRevenueSystemDepositTrackingParams,
): Promise<IActivateRevenueSystemDepositTrackingResponse> => {
  return apiPost(
    `/api/v1/revenue_services/${params.revenueServiceId}/revenue_systems/${params.revenueSystemId}/deposit_tracking`,
    underscoreKeys({ depositTracking: params.data }),
  ).then((data) => {
    return camelizeKeys(data) as IActivateRevenueSystemDepositTrackingResponse;
  });
};

interface IGetRevenueDepositsParams {
  id: TUUID,
  startDate: TDate,
  endDate: TDate,
}

interface IGetRevenueDepositsResponse {
  revenueDeposits: IRevenueDeposit[],
}

const getRevenueDeposits = (
  params: IGetRevenueDepositsParams,
): Promise<IGetRevenueDepositsResponse> => {
  return apiGet(
    `/api/v1/revenue_systems/${params.id}/revenue_deposits`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IGetRevenueDepositsResponse;
  });
};

interface IGetTemplateExcel {
  revenueServiceId: TID,
  revenueSystemId: TUUID,
}

const getTemplateExcel = async (params: IGetTemplateExcel): Promise<Blob> => {
  const url = `/api/v1/revenue_services/${params.revenueServiceId}/revenue_systems/${params.revenueSystemId}/download_revenue_mapping_template`;

  return authorizedApiRequest({
    url,
    method:       'GET',
    responseType: 'blob',
    params,
  }).then((blob) => blob);
};

const useTemplateExcel = () => {
  return useMutation<Blob, Error, IGetTemplateExcel>(getTemplateExcel);
};

export {
  IActivateRevenueSystemDepositTrackingParams,
  IActivateRevenueSystemDepositTrackingResponse,
  ICreateRevenueSystemParams,
  ICreateRevenueSystemResponse,
  IGetRevenueSystemsParams,
  IGetRevenueSystemsResponse,
  IGetRevenueDepositsParams,
  IGetRevenueDepositsResponse,
  activateRevenueSystemDepositTracking,
  createRevenueSystem,
  getRevenueSystems,
  getRevenueDeposits,
  useTemplateExcel,
};
