import React from 'react';

import { arServiceCustomersPath, arServicePath, arServicePaymentsPath, arServiceSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';

import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: TID
}

const AccountsReceivableSidebarMenuItems = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceSidebar.ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNT_RECEIVABLES }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ arServicePath(businessId) }
          title="Invoices"
        />
        <ServiceSidebar.Item
          href={ arServiceCustomersPath(businessId) }
          title="Customers"
        />
        <ServiceSidebar.Item
          href={ arServicePaymentsPath(businessId) }
          title="Payments"
        />
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href={ arServiceSettingsPath(businessId) }
          title="Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default AccountsReceivableSidebarMenuItems;
