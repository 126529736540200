module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref;
    
      if ((ref = configData.features) != null ? ref.expense_reports_dashboard : void 0) {
        __out.push('\n    <div class="expense-header">\n      <h1 class="font-24 font-bold pull-left expense-header-title">Expenses</h1>\n      <ul class="nav sub-header-menu expense-sub-menu font-semi-bold">\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.tab === 'uploaded' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.uploadedURL));
        __out.push('">\n            UPLOADED (');
        __out.push(__sanitize(this.service.get('count_uploaded')));
        __out.push(')\n          </a>\n        </li>\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.tab === 'submitted' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.submittedURL));
        __out.push('">\n            SUBMITTED (');
        __out.push(__sanitize(this.service.get('count_submitted')));
        __out.push(')\n          </a>\n        </li>\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.tab === 'reimbursed' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.reimbursedURL));
        __out.push('">\n            REIMBURSED (');
        __out.push(__sanitize(this.service.get('count_reimbursed')));
        __out.push(')\n          </a>\n        </li>\n        <li class="');
        __out.push(__sanitize(this.tab === 'rejected' ? 'active' : ''));
        __out.push('" hidden>\n          <a href="');
        __out.push(__sanitize(this.rejectedURL));
        __out.push('">\n            REJECTED (');
        __out.push(__sanitize(this.service.get('count_rejected')));
        __out.push(')\n          </a>\n        </li>\n      </ul>\n      <button class="btn btn-add-document font-23 expense-add-btn">\n        <i class="icon icon-plus"></i>\n      </button>\n    </div>\n');
      } else {
        __out.push('\n    ');
        if (!this.dwollaCustomer.get('has_funding_source')) {
          __out.push('\n    <a href="businesses/');
          __out.push(__sanitize(this.business.id));
          __out.push('/expense_reports/settings/bank_account" class="error-reconnect-panel pointer display-flex align-items-center">\n      <i class="icon icon-qbo-error in-red-600 font-20 pull-right"></i>\n      <span class="vertical-align-middle m-l-15"> There was an error connecting your bank account. Please try to reconnect. </span>\n      <span class="font-bold font-16 m-l-auto m-r-5"> Reconnect bank </span>\n    </a>\n    ');
        }
        __out.push('\n    <h2 class="font-26 font-bold border-bottom-gray p-b-20">Record an Expense Report</h2>\n    <p class="font-14 m-t-10">If you paid for a business expense using your personal credit card, bank account or cash, then send the bill or receipt to the following email: <a href="mailto:');
        __out.push(__sanitize(this.service.get('ap_upload_email')));
        __out.push('">');
        __out.push(__sanitize(this.service.get('ap_upload_email')));
        __out.push('</a></p>\n    <p class="font-14 m-t-10">Make sure to highlight your name and total amount to be reimbursed.</p>\n');
      }
    
      __out.push('\n<div class="reports-table-container"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}