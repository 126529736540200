import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID, TUUID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import { useImportRevenueCenterModal } from './modal/import_revenue_center_modal';

import styles from './styles.module.scss';

interface RevenueCaptureImportMenuProps {
  businessId: TID,
  integrationId: null | undefined | TID,
  revenueServiceId: TID,
  revenueSystemId: TUUID,
  serviceEmail: string,
  businessName: string,
  revenueSystemName: string
}

const RevenueCaptureImportMenu = ({
  businessId,
  integrationId,
  revenueServiceId,
  revenueSystemId,
  serviceEmail,
  businessName,
  revenueSystemName,
}: RevenueCaptureImportMenuProps) => {
  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.REVENUE_CENTER_MAPPING_TEMPLATE,
  );

  const importRevenueCenterModal = useImportRevenueCenterModal();

  if (!featureQuery.data || (featureQuery.data && integrationId != null)) return null;
  return (
    <>
      <Button className={ styles['ml-auto'] } variant="primary" onClick={ importRevenueCenterModal.open }>
        Import using Excel
      </Button>
      <importRevenueCenterModal.Component
        businessName={ businessName }
        revenueServiceId={ revenueServiceId }
        revenueSystemId={ revenueSystemId }
        revenueSystemName={ revenueSystemName }
        serviceEmail={ serviceEmail }
        { ...importRevenueCenterModal.props }
      />
    </>
  );
};

export default React.memo(RevenueCaptureImportMenu);
