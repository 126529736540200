import Item from './item';
import Separator from './separator';
import ServiceMenu from './service_menu';
import ServiceSidebar from './service_sidebar';

export default Object.assign(ServiceSidebar, {
  Item,
  Separator,
  ServiceMenu,
});
