import React, { FC, useState, useCallback, useRef, useEffect } from 'react';

import { deleteAttachment, putAttachmentRename, downloadAttachment } from '@src/requests/joural_entries';

import FileView from '@src/components/banking_accounts/bank_statements/bank_statement_details/bank_statement_file_view';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';

import styles from './styles.module.scss';

const Attachment: FC<{
  data: any;
  onChildClick: any;
  selectedChild: string;
  documentId: number;
}> = ({ data, onChildClick, selectedChild, documentId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fileName, setFileName] = useState(data.name);
  const [isPreview, setIsPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState(new ArrayBuffer(10));
  const containerRef = useRef(null);
  const handleFileNameChange = useCallback(() => {
    putAttachmentRename(data.id, { name: fileName }).then(() => {
      setIsEditing(false);
    });
  }, [fileName, data.id]);

  const handleClickOutside = (event: { target: any }) => {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsPreview(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProceed = useCallback(() => {
    deleteAttachment(data.id).then(() => {
      window.location.reload();
    });
  }, [data]);

  const confirmDepartmentModal = useConfirmDeleteModal({
    onDone: handleProceed,
  });

  const handleRenameClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e: any) => {
    setFileName(e.target.value);
  };

  const handleInputBlur = () => {
    handleFileNameChange();
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleFileNameChange();
      e.preventDefault();
    }
  };

  const handleFileClick = () => {
    downloadAttachment({
      documentId,
      s3ObjectKey: data.key,
    }).then(({ fileData }) => {
      setIsPreview(true);
      onChildClick(fileName);
      setPreviewContent(fileData);
    });

    onChildClick(fileName);
  };

  return (
    <div>
      {isEditing ? (
        <input
          type="text"
          value={ fileName }
          onBlur={ handleInputBlur }
          onChange={ handleInputChange }
          onKeyDown={ handleInputKeyDown }
        />
      ) : (
        <span className={ `${styles['footer-file']} ${selectedChild === fileName ? styles['footer-item-focus'] : null}` }>
          <div
            className={ isPreview ? styles['footer-file-choose'] : undefined }
            role="button"
            tabIndex={ -1 }
            onClick={ handleFileClick }
          >
            {`${fileName}`}
          </div>
          <i className="icon icon-3-dots font-20">
            <div className={ styles['footer-file-ops'] }>
              <span
                className={ styles['footer-file-ops-item'] }
                role="button"
                tabIndex={ -1 }
                onClick={ handleRenameClick }
              >
                <span className="icon icon-pencil font-15" />
                Rename
              </span>
              <span
                className={ styles['footer-file-ops-item'] }
                role="button"
                tabIndex={ -1 }
                onClick={ confirmDepartmentModal.open }
              >
                <span className="font-20 icon icon-destroy" />
                Delete
              </span>
            </div>
          </i>
        </span>
      )}
      <confirmDepartmentModal.Component
        { ...confirmDepartmentModal.props }
        confirmStyle="danger"
        confirmTitle="Delete"
        text={ (
          <span>
            Are you sure you want to delete this document?
            <br />
            This action cannot be undone.
          </span>
        ) }
        title="Delete Document"
      />
      {isPreview && (
        <div ref={ containerRef } className={ styles['footer-preivew'] }>
          <FileView fileName={ fileName } fileUrl={ new Uint8Array(previewContent) } />
        </div>
      )}
    </div>
  );
};

export default Attachment;
