import React, { useCallback } from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import Dropdown from '@src/components/ui_v2/dropdown';
import FullPageModal from '@src/components/ui_v2/full_page_modal';
import { DownArrowIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const ReportTypes = () => {
  const handleCancelClick = useCallback(() => {
    window.Docyt.vent.trigger('close:report_type_layout:page');
  }, []);

  const handleProceedClick = () => {
    window.Docyt.vent.trigger('close:report_type_layout:page');
  };

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['actions-dropdown-toggle-button'] }>
      <Button variant="primary">
        Import
        <DownArrowIcon fontSize={ 14 } />
      </Button>
    </Dropdown.ToggleButton>
  );

  const handleImportExistingMapping = () => {
    // TODO create Function
    console.log('success');
  };

  return (
    <FullPageModal>
      <FullPageModal.Header
        divider="horizontal"
        title="Mapping"
        onCancel={ handleCancelClick }
      />
      <FullPageModal.Body>
        <div className={ styles['title-body'] }>
          <p>
            Please provide mapping of report Line items with respective Chart of
            Accounts
          </p>
          <ActionsDropdown
            className={ styles['actions-dropdown-content'] }
            toggleButton={ toggleButton }
          >
            <ActionsDropdown.Action
              title="Import Existing Mapping"
              onSelect={ handleImportExistingMapping }
            />
          </ActionsDropdown>
        </div>
      </FullPageModal.Body>
      <FullPageModal.Footer
        isProceedDisabled={ false }
        proceedTitle="Done"
        onCancel={ handleCancelClick }
        onProceed={ handleProceedClick }
      >
        <FullPageModal.FooterFiller>
          <span className={ styles['footer-mapping-modal'] } />
        </FullPageModal.FooterFiller>
      </FullPageModal.Footer>
    </FullPageModal>
  );
};

export default ReportTypes;
