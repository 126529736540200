/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import { TID } from '@src/types/common';

import LikedBusinessesView from './linked_businesses_view';

import styles from './styles.module.scss';

interface IBusinessesViewProps {
  businessId: TID,
  vendorId: TID,
}

const BusinessesView = ({
  businessId,
  vendorId,
}: IBusinessesViewProps): JSX.Element | null => {
  return (
    <div className={ styles['linked-businesses-container'] }>
      <div>
        <span>
          This vendor's information is linked the following bussinesses. All edit and changes to This
          vendor will be applied across all selected bussinesses.
        </span>
      </div>
      <LikedBusinessesView
        businessId={ businessId }
        vendorId={ vendorId }
      />
    </div>
  );
};

export default BusinessesView;
