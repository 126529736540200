module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="');
    
      __out.push(__sanitize(this.w9_document ? '' : 'p-l-40 p-r-40'));
    
      __out.push(' p-t-25 p-b-25 update-document-container" style="');
    
      __out.push(__sanitize(this.isShowDocBar ? 'height: calc(100% - 50px);' : 'height: 100%;'));
    
      __out.push('">\n  <div class="document-drop-zone relative">\n    ');
    
      if (!(this.isOnlyView || this.isDisabled)) {
        __out.push('\n      ');
        if (!this.isUploading) {
          __out.push('\n        <div class="text-center document-input-wrapper">\n          ');
          if (!this.isOnlyEdit) {
            __out.push('\n            ');
            if (this.w9_document) {
              __out.push('\n              <span class="icon-new_w9_document font-90"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n            ');
            } else if (this.page === 'employee_expense') {
              __out.push('\n              <img src="');
              __out.push(__sanitize(configData.images['employee_expense_logo']));
              __out.push('" width="84" height="84">\n            ');
            } else {
              __out.push('\n              <img src="');
              __out.push(__sanitize(configData.images['new_document_logo']));
              __out.push('">\n            ');
            }
            __out.push('\n          ');
          }
          __out.push('\n          <p class="m-t-30 font-18 text-center">Drag and drop a document here.</p>\n          <p class="m-t-10 font-18 text-center">OR</p>\n          <button class="btn btn-blue-on-hover fileinput-button font-16">\n            Select from your computer\n            <input type="file" name="files[]" class="document-file-input">\n          </button>\n        </div>\n      ');
        } else {
          __out.push('\n        <div class="text-center document-input-wrapper p-t-70">\n          <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n          <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n            <div class=\'file-status-line\' style=\'width:0%\'></div>\n          </div>\n        </div>\n      ');
        }
        __out.push('\n    ');
      } else {
        __out.push('\n      <div class="text-center document-input-wrapper">\n        <p class="preview-description font-18">Select a document from left or upload from your computer</p>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div class="invoice-documents-bar ');
    
      __out.push(__sanitize(this.isDisabled ? 'view-disabled' : ''));
    
      __out.push('"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}