import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import ConfigureIntegrationAction from './actions/configure_integration_action';
import DisconectIntegrationAction from './actions/disconnect_integration_action';
import IntegrationHistoryAction from './actions/integration_history_action';
import ToggleIntegrationPauseAction from './actions/toggle_integration_pause_action';

import styles from './styles.module.scss';

interface RevenueCaptureIntegrationSubmenuProps {
  integrationId: TID
}

const RevenueCaptureIntegrationSubmenu = ({
  integrationId,
}: RevenueCaptureIntegrationSubmenuProps) => {
  const business = useBusinessContext();

  const integration = useGetIntegration({
    integrationId,
    businessId: business.id,
  });

  if (!integration) return null;

  return (
    <ActionsDropdown className={ styles['ml-auto'] }>
      {integration.data && (
        <ConfigureIntegrationAction
          externalSystemId={ integration.data?.externalSystemId }
        />
      )}
      <IntegrationHistoryAction
        integrationId={ integrationId }
      />
      {integration.data && (
        <ToggleIntegrationPauseAction integration={ integration.data } />
      )}
      <DisconectIntegrationAction
        integrationId={ integrationId }
      />
    </ActionsDropdown>
  );
};

export default React.memo(RevenueCaptureIntegrationSubmenu);
