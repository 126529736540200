module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var doc_id, document, i, j, len, len1, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
    
      __out.push('<a id="tooltip-btn" class="pointer in-black add-chat-document" data-toggle="tooltip" data-placement="top" title="Add Document">\n  <span class="icon icon-plus font-12"></span>\n</a>\n<div class="tab-divider">|</div>\n<ul class="tab-menu">\n  <li id="invoice-document-tab" class="tab-btn ');
    
      __out.push(__sanitize(this.default_document_id === this.active_tab ? 'active' : ''));
    
      __out.push('" data-id="');
    
      __out.push(__sanitize(this.default_document_id));
    
      __out.push('">\n    ');
    
      if (this.default_invoice_docyt_id && this.default_invoice_docyt_id.length > 32) {
        __out.push('\n      ');
        __out.push(__sanitize((ref = this.default_invoice_docyt_id) != null ? ref.slice(0, 32) : void 0));
        __out.push('...\n    ');
      } else {
        __out.push('\n      ');
        __out.push(__sanitize(this.default_invoice_docyt_id));
        __out.push('\n    ');
      }
    
      __out.push('\n  </li>\n</ul>\n<div class="tab-divider">|</div>\n<button class="left-btn"><i class="fa fa-angle-left font-20"></i></button>\n<ul class="tab-menu" id="tab-menu">\n  ');
    
      if (this.relatedDocuments && this.relatedDocuments.length > 0) {
        __out.push('\n    ');
        ref1 = this.relatedDocuments;
        for (i = 0, len = ref1.length; i < len; i++) {
          document = ref1[i];
          __out.push('\n      ');
          doc_id = document != null ? (ref2 = document.document_relative) != null ? ref2.id : void 0 : void 0;
          __out.push('\n      <li class="display-inline-block">\n        <div id="invoice-document-tab" class="tab-btn tab-btn-hover ');
          __out.push(__sanitize(doc_id === this.active_tab ? 'active' : ''));
          __out.push('" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n          ');
          if (this.isShowAction) {
            __out.push('\n            <div class="tab-btn-action" data-id="');
            __out.push(__sanitize(doc_id));
            __out.push('">\n              <span class="icon icon-more-icon font-14" style="rotate: 90deg;"></span>\n            </div>\n          ');
          }
          __out.push('\n          \n          <div class="tab-action-content" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n            <button class="action-item font-14 delete-btn" type="button">\n              <i class="icon icon-trashcan"></i>\n              <span>Delete</span>\n            </button>\n          </div>\n        </div>\n      </li>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.chatDocuments && this.chatDocuments.length > 0) {
        __out.push('\n    ');
        ref3 = this.chatDocuments;
        for (j = 0, len1 = ref3.length; j < len1; j++) {
          document = ref3[j];
          __out.push('\n      ');
          doc_id = document != null ? document.get('document_id') : void 0;
          __out.push('\n      <li class="display-inline-block">\n        <div id="invoice-document-tab" class="tab-btn tab-btn-hover ');
          __out.push(__sanitize(doc_id === this.active_tab ? 'active' : ''));
          __out.push('" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n          ');
          if ((document != null ? (ref4 = document.get('chat_document')) != null ? (ref5 = ref4.original_file_name) != null ? ref5.length : void 0 : void 0 : void 0) > 32) {
            __out.push('\n            ');
            __out.push(__sanitize(document != null ? (ref6 = document.get('chat_document')) != null ? (ref7 = ref6.original_file_name) != null ? ref7.slice(0, 32) : void 0 : void 0 : void 0));
            __out.push('...\n          ');
          } else {
            __out.push('\n            ');
            __out.push(__sanitize(document != null ? (ref8 = document.get('chat_document')) != null ? ref8.original_file_name : void 0 : void 0));
            __out.push('\n          ');
          }
          __out.push('\n          <div class="tab-btn-action" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n            <span class="icon icon-more-icon font-14" style="rotate: 90deg;"></span>\n          </div>\n          <div class="tab-action-content" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n            <button class="action-item font-14 delete-btn" type="button" data-id="');
          __out.push(__sanitize(doc_id));
          __out.push('">\n              <i class="icon icon-trashcan"></i>\n              <span>Delete</span>\n            </button>\n          </div>\n        </div>\n      </li>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</ul>\n<button class="right-btn"><i class="fa fa-angle-right font-20"></i></button>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}