import React from 'react';

import { IService, TServiceCode } from '@src/types/services';

import ServiceIcon from '@src/components/common/service_menu/service_icon';

const getServiceName = (serviceCode: TServiceCode) => {
  const serviceTypes = window.Docyt.Common.Constants.SERVICE_TYPES;
  const serviceNames = window.Docyt.Common.Constants.SERVICE_NAMES;

  switch (serviceCode) {
    case serviceTypes.ACCOUNT_RECEIVABLES:
      return serviceNames.ACCOUNT_RECEIVABLES;
    case serviceTypes.REVENUE_SERVICE:
      return serviceNames.REVENUE_SERVICE;
    default:
      return null;
  }
};

interface IServiceMenuProps {
  serviceCode: TServiceCode,
  service?: IService,
}

const ServiceMenu = ({
  service,
  serviceCode,
}: IServiceMenuProps) => {
  return (
    <div className="service-header btn-group">
      <div className="pull-left">
        <ServiceIcon serviceCode={ serviceCode } />
      </div>
      <div className="business-service-dropdown-menu-wrapper">
        <span className="service-header-title pointer dropdown-toggle" data-toggle="dropdown">
          { getServiceName(serviceCode) }
        </span>
        <p className="service-email-panel in-blue-900 font-12">
          <a href={ `mailto:${service?.uploadEmail}` }>
            { service?.uploadEmail }
          </a>
        </p>
      </div>
    </div>
  );
};

export default ServiceMenu;
