/* eslint-disable import/order */
import axios from 'axios';
import merge from 'lodash/merge';
import transform from 'lodash/transform';

import allPaymentAccountsImg from '@assets/images/payment_form/all_accounts.png';
import apServiceLogoImg from '@assets/images/categories/APService@3x.png';
import userManagementLogoImg from '@assets/images/Artwork.png';
import newDocumentLogoImg from '@assets/images/Group2.png';
import employeeExpenseLogoImg from '@assets/images/employee_expense_logo.png';
import newPhotoLogoImg from '@assets/images/photos.png';
import shopImg from '@assets/images/Shop@2x.png';
import noApproverImg from '@assets/images/Approvers.png';
import logoIcon from '@assets/images/favicon.png';
import accountSignupLogoImg from '@assets/images/account_signup/account_signup_logo.png';
import combinedShapeImg from '@assets/images/account_signup/combined_shape.png';
import tickerBgImg from '@assets/images/account_signup/ticker_bg.png';
import contactEmailImg from '@assets/images/account_signup/contact_email.png';
import contactPhoneImg from '@assets/images/account_signup/contact_phone.png';
import accountSignupBgImg from '@assets/images/account_signup/account_signup_bg.png';
import accountSignupBgMobileImg from '@assets/images/account_signup/account_signup_bg_mobile.png';
import userSelfImg from '@assets/images/user_self.png';
import emptyErrorsImg from '@assets/images/empty-errors.svg';
import referralImg from '@assets/images/referral.svg';
import upgradeIconImg from '@assets/images/vendor_service/upgrade_icon.png';

import accountingImg from '@assets/images/dashboard/Accounting@2x.png';
import bankTransactionsImg from '@assets/images/dashboard/bank_transactions@2x.png';
import accountPayableImg from '@assets/images/dashboard/AccountsPayable@2x.png';
import receiptBoxImg from '@assets/images/dashboard/ReceiptBox@2x.png';
import reconciliationCenterImg from '@assets/images/dashboard/ReconciliationCenter@2x.png';
import tasksImg from '@assets/images/dashboard/Tasks@2x.png';
import bankImg from '@assets/images/dashboard/bank@2x.png';
import activateServicesImg from '@assets/images/dashboard/Activate@2x.png';
import manualCheckImg from '@assets/images/dashboard/check_number@2x.png';
import businessClientsImg from '@assets/images/dashboard/business@2x.png';
import individualClientsImg from '@assets/images/dashboard/spouse@2x.png';
import clientManagementImg from '@assets/images/dashboard/client-management@2x.png';

import financialReportsImg from '@assets/images/financial_reports/FinancialReports@2x.png';
import customReportsImg from '@assets/images/financial_reports/custom-reports-illustration@2x.png';

import logoURLImg from '@assets/images/docyt-logo.png';

import emptyReportsImg from '@assets/images/multi_entity/group-5@2x.png';

import banksImg from '@assets/images/banking_accounts/banks.png';
import transactionImportImg from '@assets/images/banking_accounts/txn_icon@2x.png';
import emptyBankingAccountImg from '@assets/images/banking_accounts/empty_banking_account.png';
import duplicatedTransactionTopImg from '@assets/images/banking_accounts/duplicated-transaction-top.png';

import monthEndImg from '@assets/images/reconciliation_center/month-end.png';

import settleUpImg from '@assets/images/settlement/settle_up@2x.png';

import addAuthorizedPayerImg from '@assets/images/authorized_payer/add-authorized-payers.png';

import generateCheckImg from '@assets/images/generate_check/generate-check.png';
import revenueEmptyImg from '@assets/images/revenue_service/empty.png';

import expenseServiceImg from '@assets/images/expense_reports/expense-service-icon.png';
import expenseServiceEmployeesImg from '@assets/images/expense_reports/expense-report-employees.png';
import expenseEmptyImg from '@assets/images/expense_reports/expense-reports-empty-state.png';
import expenseAchImg from '@assets/images/expense_reports/ach-illustration.png';
import emptyUploadedImg from '@assets/images/expense_reports/uploaded.png';
import emptySubmittedImg from '@assets/images/expense_reports/submitted.png';
import emptyReimbursedImg from '@assets/images/expense_reports/reimbursed.png';
import emptyRejectedImg from '@assets/images/expense_reports/rejected.png';
import expenseCategoryImg from '@assets/images/expense_reports/categories.png';

import vendorServiceImg from '@assets/images/vendor_service/vendor-service-icon.png';
import eligibility1099ReportImg from '@assets/images/vendor_service/vendor-1099-reports-icon.png';

import metricsServiceImg from '@assets/images/metrics_service/metrics.svg';

import emptyCancelledImg from '@assets/images/receivable/empty_cancelled.png';
import emptyCompletedImg from '@assets/images/receivable/empty_completed.png';
import getStartedImg from '@assets/images/receivable/get_started.png';

import inboxServiceImg from '@assets/images/business_inbox/business-inbox-icon.png';

import dataExportsEmptyImg from '@assets/images/settings/data-exports-empty-status.png';

import paymentProcessorAmexImg from '@assets/images/payment_processors/amex.png';
import paymentProcessorCashImg from '@assets/images/payment_processors/cash.png';
import paymentProcessorChaseImg from '@assets/images/payment_processors/chase.png';
import paymentProcessorAchImg from '@assets/images/payment_processors/checksach.png';
import paymentProcessorDefaultImg from '@assets/images/payment_processors/default.png';
import paymentProcessorPaypalImg from '@assets/images/payment_processors/paypal.png';
import paymentProcessorSquareImg from '@assets/images/payment_processors/square.png';
import paymentProcessorStripeImg from '@assets/images/payment_processors/stripe.png';

import shopIcon from '@assets/images/icons/shop.svg';
import groupChatIcon from '@assets/images/icons/group-chat.svg';
import botIcon from '@assets/images/icons/bot.svg';

import appConfig from '@config/app_config.json';
import { IAppConfig } from '@src/types/app_config';
import { IEnvConfig } from '@src/types/env_config';
import { IConfig } from '@src/types/config';

const configData = {
  encryptionAlgorithm: 'AES256',
  images:              {
    all_payment_accounts:     allPaymentAccountsImg,
    ap_service_logo:          apServiceLogoImg,
    user_management_logo:     userManagementLogoImg,
    new_document_logo:        newDocumentLogoImg,
    employee_expense_logo:    employeeExpenseLogoImg,
    new_photo_logo:           newPhotoLogoImg,
    shop_image:               shopImg,
    no_approver_image:        noApproverImg,
    logo_icon:                logoIcon,
    account_signup_logo:      accountSignupLogoImg,
    combined_shape:           combinedShapeImg,
    ticker_bg:                tickerBgImg,
    contact_email:            contactEmailImg,
    contact_phone:            contactPhoneImg,
    account_signup_bg:        accountSignupBgImg,
    account_signup_bg_mobile: accountSignupBgMobileImg,
    user_self:                userSelfImg,
    empty_errors:             emptyErrorsImg,
    referral:                 referralImg,
    upgrade_icon:             upgradeIconImg,
  },
  dashboard_images: {
    ACCOUNTING:            accountingImg,
    BANK_TRANSACTIONS:     bankTransactionsImg,
    ACCOUNTS_PAYABLE:      accountPayableImg,
    RECEIPT_BOX:           receiptBoxImg,
    RECONCILIATION_CENTER: reconciliationCenterImg,
    TASKS:                 tasksImg,
    BANK:                  bankImg,
    ACTIVATE_SERVICES:     activateServicesImg,
    MANUAL_CHECK:          manualCheckImg,
    BUSINESS_CLIENTS:      businessClientsImg,
    INDIVIDUAL_CLIENTS:    individualClientsImg,
    CLIENT_MANAGEMENT:     clientManagementImg,
  },
  reports_images: {
    FINANCIAL_REPORTS: financialReportsImg,
    CUSTOM_REPORTS:    customReportsImg,
  },
  multi_reports_images: {
    EMPTY_REPORTS: emptyReportsImg,
  },
  banking_accounts_images: {
    BANKS:                      banksImg,
    TRANSACTION_IMPORT:         transactionImportImg,
    EMPTY_BANKING_ACCOUNT:      emptyBankingAccountImg,
    DUPLICATED_TRANSACTION_TOP: duplicatedTransactionTopImg,
  },
  reconciliation_center_images: {
    MONTH_END: monthEndImg,
  },
  settlement_images: {
    SETTLE_UP: settleUpImg,
  },
  authorized_payer_images: {
    ADD_AUTHORIZED_PAYER: addAuthorizedPayerImg,
  },
  generate_check_images: {
    GENERATE_CHECK: generateCheckImg,
  },
  revenue_images: {
    REPORT_EMPTY: revenueEmptyImg,
  },
  expense_images: {
    EXPENSE_SERVICE:           expenseServiceImg,
    EXPENSE_SERVICE_EMPLOYEES: expenseServiceEmployeesImg,
    EXPENSE_EMPTY:             expenseEmptyImg,
    EXPENSE_ACH:               expenseAchImg,
    EMPTY_UPLOADED:            emptyUploadedImg,
    EMPTY_SUBMITTED:           emptySubmittedImg,
    EMPTY_REIMBURSED:          emptyReimbursedImg,
    EMPTY_REJECTED:            emptyRejectedImg,
    EXPENSE_CATEGORY:          expenseCategoryImg,
  },
  vendor_images: {
    VENDOR_SERVICE:          vendorServiceImg,
    ELIGIBILITY_1099_REPORT: eligibility1099ReportImg,
  },
  metric_images: {
    METRICS_SERVICE: metricsServiceImg,
  },
  receivable_images: {
    EMPTY_CANCELLED: emptyCancelledImg,
    EMPTY_COMPLETED: emptyCompletedImg,
    GET_STARTED:     getStartedImg,
  },
  business_inbox_images: {
    INBOX_SERVICE: inboxServiceImg,
  },
  settings_images: {
    DATA_EXPORT_EMPTY: dataExportsEmptyImg,
  },
  payment_processor_images: {
    american_express:           paymentProcessorAmexImg,
    cash_checks:                paymentProcessorCashImg,
    chase_paymentech:           paymentProcessorChaseImg,
    docyt_ach:                  paymentProcessorAchImg,
    default:                    paymentProcessorDefaultImg,
    paypal:                     paymentProcessorPaypalImg,
    square:                     paymentProcessorSquareImg,
    stripe_payments_processing: paymentProcessorStripeImg,
  },
  logoURL:                               logoURLImg,
  onboarding_video_embed_url:            'https://www.youtube.com/embed/H5h6RkyNoJQ',
  onboarding_accountant_video_embed_url: 'https://www.youtube.com/embed/Im3RIZA7N2E',
  onboarding_video_url:                  'https://youtu.be/H5h6RkyNoJQ',
  icons:                                 {
    SHOP:       shopIcon,
    GROUP_CHAT: groupChatIcon,
    BOT:        botIcon,
  },
};

window.DEFAULT_SAMPLE_IMAGE_WIDTH = 8.5;
window.SEARCH_DEBOUNCE_INTERVAL = 500;
window.PERCENT_FROM_TOP = 90;

/* eslint-disable no-useless-escape */
// We don't have tests for this regexp so I don't want to touch them
// to prevent any errors that I don't know how to test.
window.PHONE_REGEXP = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
window.EMAIL_REGEXP = /^\w+([\._%+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
window.PASSWORD_REGEXP = /^(?=.*[A-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
/* eslint-enable no-useless-escape */

const keysToUpperCase = (obj: object): object => {
  return transform(obj, (result: { [key: string]: any }, value: any, key: string) => {
    result[key.toUpperCase()] = value;
  }, obj);
};

const loadConfigData = (): Promise<IConfig> => {
  return axios
    .get('/env_config.json')
    .then((response) => {
      const envConfig = keysToUpperCase(response.data) as IEnvConfig;
      const appConfigUp = keysToUpperCase(appConfig) as IAppConfig;

      window.configData = merge(appConfigUp, envConfig, configData);

      return window.configData;
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error on loading environment config: ', error);
      throw error;
    });
};

export { loadConfigData };
